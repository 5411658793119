import React, {Component} from 'react';
import {Router} from '../common/core/director';
import Modules from '../config/modulesDelear';
import AppNavHeader from '../module/component/app_header/AppNavHeader';
import AppPermissionValidate from "../module/component/AppPermissionValidate";

class Module extends Component {
    constructor(props) {
        super(props);
        let map={};
        Modules.forEach((c) =>{
            map[c.name]=c;
        });
        this.state = {
            loginUser:props.loginUser,
            moduleMap:map,
            moduleList:Modules,
            currentModuleComponent:'',
            currentModuleConfig:'',
            currentModuleParams:''
        };
        // this.getUserSchool = this.getUserSchool.bind(this);
        this.initRouter = this.initRouter.bind(this);
        this.changeRouter = this.changeRouter.bind(this);
    }
    componentDidMount() {
        // this.getUserSchool(()=>{
            this.initRouter();
        // });
    }
     initRouter=()=>{
         let self=this;
         self.router=new Router();
         let modules=this.state.moduleList;
         let indexModule=modules[0];
         //注册默认路由
         self.router.configure({
             notfound:function(...param){
                 self.changeRouter(indexModule,param);
             },
             async: true
         });
         modules.forEach((m) =>{
             if(typeof m.router ==='object' && m.router.length){
                 m.router.forEach(function (mitem) {
                     self.router.on(mitem,(...param) =>{
                         self.changeRouter(m,param);
                     });
                 })
             }else{
                 self.router.on(m.router,(...param) =>{
                     self.changeRouter(m,param);
                 });
             }
         })
         self.router.init('/');
     }
     changeRouter =(module,param) =>{
         let self=this;
         if(module){
             let params=[];
             if(param && param.length){
                 params=param.filter(function (p) {
                     return typeof p==='string'? true:false;
                 })
             }
             self.loadModule(module,params);
         }
     }
     loadModule=(module,params)=>{
         let self=this;
         module.component((moduleComponent) => {
             // console.dir(module)
             //console.log(params)
             self.setState({
                 currentModuleComponent:moduleComponent,
                 currentModuleConfig:module,
                 currentModuleParams:params
             })
         })
     }
    // getUserSchool(callback){
    //     let self=this;
    //     let {loginUser} = self.state;
    //     window.ctx.getUserSchool(loginUser._id,(res)=>{
    //         if(res && res.data){
    //             window.ctx.schoolUser=res.data.schoolUser;
    //             window.ctx.school=res.data.school;
    //             self.setState({
    //                 schoolUser:res.data.schoolUser,
    //                 school:res.data.school,
    //             },callback)
    //         }else{
    //             window.ctx.alert('禁止使用：当前用户不属于任何学校，请联系管理员或使用其他账号进行登录！',()=>{
    //                 window.ctx.logout();
    //             },false);
    //         }
    //     })
    // }
    render() {
        let self=this;
        let {school,schoolUser} =self.state;
        let C = self.state.currentModuleComponent;
        return C ?
            <div className="module-paranel app-layout-wrap">
            <div className="app-layout-header"><AppNavHeader parent={self}
                 moduleConfig={self.state.currentModuleConfig}
                 moduleParams={self.state.currentModuleParams}
                 loginUser={self.state.loginUser}
                 schoolUser={schoolUser}
            /></div>
            <div className="app-layout-module-content">
            <AppPermissionValidate key={self.state.currentModuleConfig.name} permission={self.state.currentModuleConfig.permission}>
                <C key={self.state.currentModuleConfig.name} parent={self}
                                            moduleConfig={self.state.currentModuleConfig}
                                            moduleParams={self.state.currentModuleParams}
                                            loginUser={self.state.loginUser}
                                           schoolUser={schoolUser}
                                           school={school}
                />
            </AppPermissionValidate>
            </div>
            </div>
        :null
        }
}
export default Module