import axios from 'axios';
import Utils from './Util';
class API {
    constructor(config,env){
        let _self=this;
        let _env=env;
        let _appConf=config;
        let _apiInstanceConf=_appConf.apiInstance.api;
        let _globalQuerys,
            _globalError,
            _globalHeaders=Object.assign({
                'Content-Type': 'application/json',
            }, _appConf.apiInstance.header || {});
        let _cache={};
        let _timeExpress=(_env.cacheExpires || 300 ) *1000;
        /* 数据缓存方法*/
        //存储数据
        _self.setCache=function(scopePath,key,data){
            if(scopePath && key){
                let time=Date.now();
                let _scopeCache=_cache[scopePath];
                if(!_scopeCache){
                    _cache[scopePath]=_scopeCache={};
                }
                _scopeCache[key]={
                    data:JSON.stringify(data),
                    updateTime:time
                }
            }
        };
        //删除数据
        _self.removeCache=function(scopePath,key){
            if(scopePath && key){
                let scope = _cache[scopePath];
                if(scope){
                    Reflect.deleteProperty(scope,key);
                }
            }
        };
        //清除数据
        _self.clearCacheScope=function(scopePath){
            Reflect.deleteProperty(_cache,scopePath);
        };
        //读取数据
        _self.getCache=function(scopePath,key){
            let data;
            let time=Date.now();
            if(scopePath && key){
                let _scopeCache = _cache[scopePath];
                if(_scopeCache){
                    let c=_scopeCache[key];
                    if(c){
                        if((time-c.updateTime)<_timeExpress){
                            data=c.data;
                        }else{
                            _self.removeCache(scopePath,key);
                        }
                    }
                }
            }
            return data ?JSON.parse(data):data;
        };
        /* 数据缓存方法 end*/

        /* 设置ajax全局对象方法*/
        //设置全局请求头
        _self.setGlobalHeaders=function (obj) {
            let o=_globalHeaders;
            _globalHeaders=Object.assign(o,obj);
        }
        _self.getGlobalHeaders=function (obj) {
            return _globalHeaders;
        }
        //删除全局请求头
        _self.removeGlobalHeader=function (key) {
            if(_globalHeaders && _globalHeaders[key]!==undefined){
                Reflect.deleteProperty(_globalHeaders,key);
            }
        }
        //设置url全局查询参数
        _self.setGlobalQuerys=function (obj) {
            let o=_globalQuerys || {};
            _globalQuerys=Object.assign(o,obj);
        }
        //设置ajax全局错误回调函数
        _self.setGlobalError=function (fun) {
            _globalError=fun;
        }
        /* 设置ajax全局对象方法 end*/

        _self.getQueryString=(querys)=>{
            let strs=[];
            for(let key in querys){
                let v=querys[key];
                let t=typeof v;
                if( 'object'===t || t.length >=0 ){
                    v=JSON.stringify(v);
                }
                if(v){
                    strs.push(key+'='+v);
                }
            }
            return strs.join('&');
        };

        _self.axios=axios;
        _self.axios.defaults.timeout = 1000*20;
        let _defOpt={
            method:'get',
            baseURL:'',
            timeout:30000,
            responsetype:'json'
        }
        _self.sendRequest=(baseURL,option,param={},callback,error,retryCount)=>{
            retryCount = retryCount || 0;
            let useCache=false;
            if( _env.useCache && option.useCache){
                useCache=true;
            }
            let _customErrorCode=param['customCode'];
            if(_customErrorCode){
                Reflect.deleteProperty(param,'customCode');
            }
            if(param.useCache!==undefined){
                useCache=param.useCache? true:false;
                Reflect.deleteProperty(param,'useCache');
            }
            let _url=option.url;
            if(param.urlParams && typeof param.urlParams ==='object'){
                _url=option.url.format(param.urlParams);
                Reflect.deleteProperty(param,'urlParams');
            };
            let cacheData;
            let scopePath;
            let key
            if(useCache && callback){
                let path=_url;
                let startTime=Date.now();
                scopePath=Utils.hashCode(path)
                key = Utils.hashCode(JSON.stringify(param));
                cacheData=_self.getCache(scopePath,key);

                env.envKey!=='prod' && console.log('API [{path}] scopePath:[{scopePath}] key:[{key} ] process time use:[{use}]ms match data:[{hasData}]'.format({
                    path,
                    scopePath,
                    key,
                    hasData:cacheData?'yes':'no',
                    use:Date.now()-startTime
                }))
            }
            if(cacheData){
                if(typeof callback ==='function'){
                    callback(cacheData);
                }
            }else{
                let opt=Object.assign(_defOpt,option);
                opt.baseURL=baseURL;
                opt.url=_url;
                opt.port=443;
                if(_globalHeaders){
                    opt.headers=_globalHeaders;
                }
                if(opt['x_lx_auth']){
                    opt.headers=opt.headers || {};
                    opt.headers['X-LX-AUTH']=1;
                }
                if(opt.method==='get'){
                    opt.params = Object.assign( _globalQuerys || {}, param || {});
                }else if(opt.method==='getDown'){
                    return window.open(opt.baseURL.concat(opt.url))
                }else {
                    opt.params = _globalQuerys || {};
                    opt.data = param;
                }
                let successCall=function(res){
                    if(useCache){
                        if(res.data && !(res.data._notice || res.data._error)){
                            _self.setCache(scopePath,key,res.data);
                        }
                    }
                    callback && callback(res.data);
                }
                let errorCall=function(err){
                    console.error(err);
                    if(err && err.response && err.response.data && err.response.data._notice){
                        callback && callback(err.response.data);
                    }else if(err && err.request){
                        if(!err.request.status && retryCount<=3){
                            // if(err && retryCount<3){
                            setTimeout(()=>{
                                retryCount++;
                                console.debug('retry:'+retryCount);
                                let promiss = axios(opt);
                                promiss.then(successCall).catch(errorCall);
                            },500)
                            return false;
                        }else{
                            if(typeof error === 'function'){
                                error(err);
                            }else if (typeof _globalError === 'function') {
                                if(_customErrorCode && _customErrorCode[err.request.status]){
                                    _customErrorCode[err.request.status](err);
                                }else{
                                    _globalError(err);
                                }
                            }
                        }
                    }
                }
                let promiss = axios(opt);
                if(typeof callback === 'function'){
                    promiss.then(successCall).catch(errorCall);
                }else{
                    return promiss;
                }
            }
        };
        _self.transformApi=(instanceConf)=>{
            function renderApi (root, apiConfigs) {
                Object.keys(apiConfigs).forEach((key)=>{
                    let apiConf=apiConfigs[key];
                    if(apiConf.url && apiConf.method){
                        let _host=_env.apiHost;
                        if(apiConf.host && _env[apiConf.host]){
                            _host = _env[apiConf.host];
                        }
                        if(apiConf.host && _env[apiConf.host]){
                            _host = _env[apiConf.host];
                        }
                        if(apiConf.base){
                            _host = apiConf.base;
                        }
                        if(apiConf.localHost){
                            _host = apiConf.localHost;
                        }
                        root[key]=function (baseURL,axiosOption,param,callback,error) {
                            _self.sendRequest(baseURL,axiosOption,param,callback,error)
                        }.bind(_self,_host,apiConf);
                    }else{
                        let obj=root[key]={};
                        renderApi(obj,apiConf);
                    }
                });
            }
            renderApi(_self, instanceConf);
        };
        _self.transformApi(_apiInstanceConf || {});
    }
}
export default API