import React, {Component} from 'react';
import {Router} from '@core/director';
import OrgModules from '../config/modulesOrg';
import OrgTutorModules from '../config/modulesOrgTutor';
import AppOrgNavHeader from '@com/app_header/org_nav_header';
import AppPermissionValidate from "@com/AppPermissionValidate";
import OrgService from '@sev/OrgService';

class Module extends Component {
    constructor(props) {
        super(props);
        this.service = window.ctx.createService(OrgService);
        this.state = {
            loginUser:props.loginUser,
            moduleMap:'',
            moduleList:'',
            org:'',
            school:'',
            schoolUser:'',
            schoolUsers:'',
            currentModuleComponent:'',
            currentModuleConfig:'',
            currentModuleParams:''
        };

        this.getUserOrg = this.getUserOrg.bind(this);
        this.getUserSchool = this.getUserSchool.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.initRouter = this.initRouter.bind(this);
        this.changeRouter = this.changeRouter.bind(this);
    }
    componentDidMount() {
        this.getUserOrg(()=>{
            this.initRouter();
        });
    }
     initRouter=()=>{
         let self=this;
         self.router=new Router();
         let modules=this.state.moduleList;
         let indexModule=modules[0];
         //注册默认路由
         self.router.configure({
             notfound:function(...param){
                 self.changeRouter(indexModule,param);
             },
             async: true
         });
         modules.forEach((m) =>{
             if(typeof m.router ==='object' && m.router.length){
                 m.router.forEach(function (mitem) {
                     self.router.on(mitem,(...param) =>{
                         self.changeRouter(m,param);
                     });
                 })
             }else{
                 self.router.on(m.router,(...param) =>{
                     self.changeRouter(m,param);
                 });
             }
         })
         self.router.init('/');
     }
     changeRouter =(module,param) =>{
         let self=this;
         if(module){
             let params=[];
             if(param && param.length){
                 params=param.filter(function (p) {
                     return typeof p==='string'? true:false;
                 })
             }
             self.loadModule(module,params);
         }
     }
     loadModule=(module,params)=>{
         let self=this;
         module.component((moduleComponent) => {
             // console.dir(module)
             //console.log(params)
             self.setState({
                 currentModuleComponent:moduleComponent,
                 currentModuleConfig:module,
                 currentModuleParams:params
             })
         })
     }
     changeUser(org,schoolUser,schoolUsers,callback){
        let self=this;
        let {loginUser}=self.state;
        let school=schoolUser?schoolUser.school:null;
         window.ctx.org=org;
         window.ctx.schoolUser=schoolUser;
         window.ctx.school=school;
         window.ctx.schoolUsers=schoolUsers;
         let moduleList = schoolUser && schoolUsers?OrgTutorModules:OrgModules;
         let moduleMap  = {};
         moduleList = moduleList.filter((m)=>{
             if(m.role){
                 if(loginUser.role.body.indexOf(m.role)>=0){
                     moduleMap[m.name]=m;
                     return true;
                 }else{
                     return false;
                 }
             }else{
                 moduleMap[m.name]=m;
                 return true;
             }
         })
         self.setState({
             moduleList,
             moduleMap,
             org,
             school,
             schoolUser,
             schoolUsers
         },callback)
     }
    getUserOrg(callback){
        let self=this;
        // let {loginUser} = self.state;
        if(self.state.loginUser.role.body.indexOf('org_manager')>=0){
            self.service.org.my({},(res)=>{
                if(res && res.org && res.org.length){
                    self.changeUser(res.org[0],null,null,callback);
                }else{
                    window.ctx.alert('禁止使用：当前用户不属于任何机构，请联系管理员或使用其他账号进行登录！',()=>{
                        window.ctx.logout();
                    },false);
                }
            })
        }else if(self.state.loginUser.role.body.indexOf('org_teacher')>=0){
            self.getUserSchool(null,callback);
        }

    }
    getUserSchool(org,callback){
        let self=this;
        let {loginUser} = self.state;
        window.ctx.getUserSchool(loginUser._id,['org_teacher'],(res)=>{
            if(res && res.data){
                let {schoolUser,schoolUsers} = res.data;
                let useSchoolUsers = [];
                let useSchoolUser;
                if(schoolUsers && schoolUsers.length){
                    schoolUsers.forEach((su)=>{
                        if(su.school && su.school.orgId){
                            useSchoolUsers.push(su);
                        }
                    })
                    useSchoolUser=useSchoolUsers[0];
                }
                if(useSchoolUser){
                    self.changeUser(org,useSchoolUser,useSchoolUsers,callback);
                }else{
                    window.ctx.alert('禁止使用：当前用户不属于任何学校，请联系管理员或使用其他账号进行登录！',()=>{
                        window.ctx.logout();
                    },false);
                }
            }else{
                window.ctx.alert('禁止使用：当前用户不属于任何学校，请联系管理员或使用其他账号进行登录！',()=>{
                    window.ctx.logout();
                },false);
            }
        })
    }
    handleSchoolUserChange(schoolUser){
        let self=this;
        self.changeUser(self.state.org,schoolUser,self.state.schoolUsers);
    }
    render() {
        let self=this;
        let state= self.state;
        let {org,schoolUser,school,schoolUsers} = state;
        let C = state.currentModuleComponent;
        let keyObj = org || schoolUser;
        return (keyObj) && C ?
            <div className="module-paranel app-layout-wrap">
            <div className="app-layout-header"><AppOrgNavHeader key={keyObj._id} parent={self}
                                                      moduleConfig={state.currentModuleConfig}
                                                      moduleParams={state.currentModuleParams}
                                                      loginUser={state.loginUser}
                                                        schoolUser={schoolUser}
                                                        school={school}
                                                        schoolUsers={schoolUsers}
                                                      org={org}
                                                     onSchoolUserChange={self.handleSchoolUserChange.bind(self)}
            /></div>
            <div className="app-layout-module-content">
            <AppPermissionValidate key={`${keyObj._id}_${state.currentModuleConfig.name}`} permission={state.currentModuleConfig.permission}>
                <C key={`${keyObj._id}_${state.currentModuleConfig.name}`} parent={self}
                                            moduleConfig={state.currentModuleConfig}
                                            moduleParams={state.currentModuleParams}
                                            loginUser={state.loginUser}
                                           schoolUser={schoolUser}
                                           school={school}
                                           schoolUsers={schoolUsers}
                                            org={org}
                />
            </AppPermissionValidate>
            </div>
            </div>
        :null
        }
}
export default Module