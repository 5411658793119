import {Component} from 'react';

class Module extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitState(props);
    }
    getInitState(props) {
        let state = {};

        return state;
    }
    render() {
        let permission=this.props.permission;
        let permissionsMap =window.ctx.permissionsMap;
        if(permission){
            if(permissionsMap && (permissionsMap[":super"] || permissionsMap[permission])){
                return this.props.children;
            }else{
                return '';
            }
        }else{
            return this.props.children;
        }
    }
}

export default Module
