import React, {Component} from 'react';
import  '../css/login.css';
import logo from '../img/AppImg/logo.png';

import bg from '../img/AppImg/bg_01.png';
class Module extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	componentDidMount() {

	}
	handleSubmit(e) {
		e.preventDefault();
		let user = this.refs.user.value.trim();
		let pwd = this.refs.pwd.value.trim();
		if(user && pwd) {
			window.ctx.login({
				"user": user,
				"password": pwd,
                "noSub":true,
			}, function(res) {
				window.ctx.cookieUserName(user);
				if(res._notice) {
					window.ctx.alertNotice(res._notice);
				}
			})
		}

	}
	render() {
		let cokieUName=window.ctx.cookieUserName();
		let app = window.ctx.ctxParam.app;
		return(
			<div className="module-login">
				<div className="layout-header">
					<header className="header " style={{background:'transparent'}}>
						<div className="header-item-group">
							<div className="header-item">
								<img className="logo" src={app.logoImg || logo}  alt=""/>
							</div>
							<div className="header-item">
								{app.logoText}
							</div>
						</div>

					</header>
				</div>
				<div className="layout-content">
					<div className="login-content-wrap">
						<div className="login-content">
							<div className="login-form-title">账号登录</div>
							<form style={{
							    padding:".2rem 0"
                            }}>
								<div className="login-form-row">
									<input className="form-ctrl" type="text" ref="user" placeholder="手机号码/电子邮箱" defaultValue={cokieUName} />
								</div>
								<div className="login-form-row">
									<input className="form-ctrl" ref="pwd" type="password" placeholder="密码"  defaultValue=""/>
								</div>
								<div className="login-form-row">
									<a className="btn loginSubmit inline blue btn-login"  onClick={this.handleSubmit.bind(this)}>登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录</a>
								</div>
							</form>
						</div>
					</div>
				</div>

			</div>
		)
	}
}
export default Module