import React, {Component} from 'react';
import {Router} from '../common/core/director';
import Modules from '../config/modules';
import AppNavHeader from './component/app_header/AppNavHeader';
import AppPermissionValidate from "./component/AppPermissionValidate";

class Module extends Component {
    constructor(props) {
        super(props);
         let map={};
         Modules.forEach((c) =>{
             map[c.moduleName]=c;
         });
         this.state = {
             loginUser:props.loginUser,
             moduleMap:map,
             moduleList:Modules,
             currentModuleComponent:'',
             currentModuleConfig:'',
             currentModuleParams:''
         };
    }
    componentDidMount() {
        this.initRouter();
    }
     initRouter=()=>{
         let self=this;
         self.router=new Router();
         let modules=this.state.moduleList;
         let indexModule=modules[0];
         //注册默认路由
         self.router.configure({
             notfound:function(...param){
                 self.changeRouter(indexModule,param);
             },
             async: true
         });
         modules.forEach((m) =>{
             if(typeof m.router ==='object' && m.router.length){
                 m.router.forEach(function (mitem) {
                     self.router.on(mitem,(...param) =>{
                         self.changeRouter(m,param);
                     });
                 })
             }else{
                 self.router.on(m.router,(...param) =>{
                     self.changeRouter(m,param);
                 });
             }
         })
         self.router.init('/');
     }
     changeRouter =(module,param) =>{
         let self=this;
         if(module){
             let params=[];
             if(param && param.length){
                 params=param.filter(function (p) {
                     return typeof p==='string'? true:false;
                 })
             }
             self.loadModule(module,params);
         }
     }
     loadModule=(module,params)=>{
         let self=this;
         module.component((moduleComponent) => {
             // console.dir(module)
             //console.log(params)
             self.setState({
                 currentModuleComponent:moduleComponent,
                 currentModuleConfig:module,
                 currentModuleParams:params
             })
         })
     }

    render() {
        return this.state.currentModuleComponent?
            <div className="module-paranel app-layout-wrap">
            <div className="app-layout-header"><AppNavHeader parent={this}
                                                      moduleConfig={this.state.currentModuleConfig}
                                                      moduleParams={this.state.currentModuleParams}
                                                      loginUser={this.state.loginUser}
            /></div>
            <div className="app-layout-module-content">
            <AppPermissionValidate key={this.state.currentModuleConfig.name} permission={this.state.currentModuleConfig.permission}><this.state.currentModuleComponent key={this.state.currentModuleConfig.name} parent={this}
                                            moduleConfig={this.state.currentModuleConfig}
                                            moduleParams={this.state.currentModuleParams}
                                            loginUser={this.state.loginUser}
            /></AppPermissionValidate>
            </div>
            </div>
        :<div></div>
        }
}
export default Module