let modules=[
    {
        title:'机构',
        name:'my_org',
        // permission:'dashi_client_menu_tutor_tools',
        router:['/my_org','/my_org/:key'],
        component:(cb)=>{
            import('../org_module/my_org/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'内容',
        name:'org_content',
        // permission:'dashi_client_menu_tutor_homework',
        router:['/org_content','/org_content/:key'],
        component:(cb)=>{
            import('../org_tutor_module/content/index').then(({default: component}) => {
                cb(component)
            })
        }
    },

];
export default modules;