let modules=[
    // {
    //     title:'首页',
    //     moduleName:'home',
    //     menu:'home',
    //     router:['/'],
    //     component:(cb)=>{
    //         require.ensure([], (require) => {
    //             cb(require('../module/home/index').default)
    //         },'module/home')
    //     }
    // },
    {
        title:'内容',
        name:'tutor_content',
        // permission:'dashi_client_menu_tutor_homework',
        router:['/tutor_content','/tutor_content/:key'],
        component:(cb)=>{
            import('../tutor_module/content/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'作业',
        name:'tutor_homework',
        // permission:'dashi_client_menu_tutor_homework',
        router:['/tutor_homework','/tutor_homework/:key'],
        component:(cb)=>{
            import('../tutor_module/homework/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'互动课堂',
        name:'tutor_hudong',
        // permission:'dashi_client_menu_tutor_homework',
        router:['/tutor_hudong','/tutor_hudong/:key'],
        component:(cb)=>{
            import('../tutor_module/hudong/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'考试',
        name:'tutor_exam',
        // permission:'dashi_client_menu_tutor_homework',
        router:['/tutor_exam','/tutor_exam/:key'],
        component:(cb)=>{
            import('../tutor_module/exam/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'班级',
        name:'tutor_classes',
        // permission:'dashi_client_menu_tutor_homework',
        router:['/tutor_classes','/tutor_classes/:key'],
        component:(cb)=>{
            import('../tutor_module/classes/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    // {
    //     title:'考试',
    //     name:'tutor_exam',
    //     // permission:'dashi_client_menu_tutor_exam',
    //     router:['/tutor_exam','/tutor_exam/:key'],
    //     component:(cb)=>{
    //         require.ensure([], (require) => {
    //             cb(require('../tutor_module/exam/index').default)
    //         },'tutor_module/exam')
    //     }
    // },
    {
        title:'工具',
        name:'tutor_tools',
        // permission:'dashi_client_menu_tutor_tools',
        router:['/tutor_tools','/tutor_tools/:key'],
        component:(cb)=>{
            import('../tutor_module/tools/index').then(({default: component}) => {
                cb(component)
            })
        }
    },

];
export default modules;