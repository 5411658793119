import React, {Component} from 'react';
import cx from 'classnames';
import MessageTip from './message-tip';
import './index.css';

class Module extends Component {
    constructor(props, context) {
        super(props, context);

        this.initFunctions(this);
        this.state = this.getInitState(props, context);
    }

    getInitState(props, context) {
        let state = {
            list:[]
        };

        return state;
    }

    //componentWillReceiveProps(props) {}

    componentDidMount() {
    }

    //componentWillUnmount() {}
    initFunctions(self) {
        self.push=(text)=>{
            let {list} = self.state;
            list.push({
                key:Date.now(),
                text:text,
            });
            self.setState({list})
        }
    }
    handleItemClose(tipItem){
        let self=this;
        self.setState((state)=>{
            return {
                list:state.list.filter((item)=>{return tipItem.key!==item.key;})
            }
        })
    }
    render() {
        let self = this;
        let state = self.state;
        let {list} = state;
        return (
            <div className={cx("app-side-top-controller", this.props.className)}>
                {
                    list && list.length?list.map((item,i)=>{
                        return <MessageTip key={item.key} onClose={self.handleItemClose.bind(self,item)} text={item.text}/>
                    }):null
                }
            </div>
        )
    }
}

export default Module
