import React, {Component} from 'react';
import cx from 'classnames';
import {Icon} from 'antd';

import './index.css';

const schoolConfig = {
    // '':{
    //     tip:'成功连接「移动云」服务，正在使用中！'
    // },
    'imei':{
        tip:'成功连接「甘肃移动云」服务，正在使用中！'
    },
    'esop':{
        tip:'成功连接「河南移动云」服务，正在使用中！'
    }
}

class Module extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitState(props);
    }

    getInitState(props) {
        let state = {
            opend:false,
            schoolUsers:props.schoolUsers,
            selectedSchoolUser:props.selectedSchoolUser
        };

        return state;
    }

    componentWillReceiveProps(props) {
    }

    componentDidMount() {
        let self=this;
        setTimeout(()=>{
            self.shouldAlertSchoolCloudTip(self.state.selectedSchoolUser.school);
        },2000)
    }

    componentWillUnmount() {
    }
    shouldAlertSchoolCloudTip(school){
        let schoolConf;
        if(school && schoolConfig[school.externalType]){
            schoolConf = schoolConfig[school.externalType];
        }
        if(schoolConf){
            window.ctx.sideTiper.push(schoolConf.tip);
        }
    }
    handleItemClick(schoolUser,event){
        event.preventDefault();
        event.stopPropagation();
        let self=this;
        self.setState({
            opend:false,
            selectedSchoolUser:schoolUser
        },()=>{
            // self.shouldAlertSchoolCloudTip(schoolUser.school);
            self.props.onChange && self.props.onChange(schoolUser);
        })
    }
    toggleOpend(){
        this.setState((state)=>{
            return {opend:!state.opend}
        })
    }
    render() {
        let self = this;
        let state = self.state;
        let {schoolUsers,selectedSchoolUser,opend} = state;
        let schoolUserList=[]
        schoolUsers.forEach((s)=>{
            if(selectedSchoolUser._id!==s._id){
                schoolUserList.push( <div key={s._id} className="school-item" onClick={self.handleItemClick.bind(self,s)}><label className="text-label">{s.school.name}</label></div>)
            }
        })
        return (
            <div className={cx("com-header-school-selector", this.props.className)} onClick={self.toggleOpend.bind(self)}>
                <div className="school-label">
                    <label className="text-label">{selectedSchoolUser.school.name}</label>
                    {
                        schoolUserList && schoolUserList.length?<Icon style={{marginLeft:'0.5em'}} type={'down'}/>:null
                    }
                </div>
                {
                    schoolUserList && schoolUserList.length?<div className={cx('list-wrap',{opend:opend})}>{schoolUserList}</div>:null
                }
            </div>
        )
    }
}

export default Module
