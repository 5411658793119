import Service from '@core/Service';
import Utils from "@core/Util";

const displayName='ContentService';
const _storage=window.localStorage;

class ModuleService extends Service{
    constructor(api){
        super(api);
        let self=this;
        let defExpires=7;
        // let defDomain='.lianxue.com';
        self.events={
            token_success:'token_success'
        };
        self.storage = function(key,value){
            if(value === undefined){
                return _storage.getItem(key);
            }else if(value === '') {
                return _storage.removeItem(key);
            }else{
                return _storage.setItem(key,value);
            }
        };
        self.storageJson = function(key,obj){
            if(obj === undefined){
                let str = _storage.getItem(key);
                if(str && str.length){
                    return JSON.stringify(str);
                }else{
                    return null
                }
            }else if(obj === '') {
                return _storage.removeItem(key);
            }else{
                if(typeof obj ==='object'){
                    return _storage.setItem(key,JSON.stringify(obj));
                }else{
                    return _storage.setItem(key,obj);
                }
            }
        };
        self._cookie=function(key,value,expires){
            return self.storage(key,value,expires);
            // if(value === undefined){
            //     return Cookies.get(key);
            // }else if(value === '') {
            //     Cookies.remove(key);
            // }else{
            //     Cookies.set(key,value,{expires:expires});
            // }

        };
        self.cookieToken=function(value,expires){
            return self._cookie('_jwt_',value,expires || defExpires);
        };
        self.cookieUserName=function(value){
            return self._cookie('_n_',value);
        };
        self.logout= function () {
            self.cookieToken('');
            window.location.hash="";
            window.location.reload();
        };
        self.login=function(param,callback,error){
            self.api.auth.login(param,function (res) {
                if(res.token){
                    self.dispatchEvent(self.events.token_success,res)
                }
                callback && callback(res);
            },error)
        };
        self.getUserBaseInfo=function(param,callback){
            self.api.auth.getUserBaseInfo(param,function (res) {
                if(res.token){
                    self.dispatchEvent(self.events.token_success,res.token)
                }
                callback && callback(res);
            })
        };
        self.getMyPermissions=function(param,callback){
            self.api.acl.permission.my(param,function (res) {
                callback && callback(res);
            })
        };
        self.getCosConfig=(callback)=>{
            let envKey = window.ctx.ctxParam.env.envKey;
            let cos = window.ctx.ctxParam.env.cos || {
                secretId:'',
                secretKey:'',
                bucket :envKey==='prod'?'main-1256408168':'test-1256408168',
                region:'ap-shanghai',
                appid : 1256408168,
                serverKey:'qbank/question'
            };
            if(cos && cos.secretKey ){
                callback && callback(cos);
            }else{
                self.api.uranus.cosEnv({},(res)=>{
                    window.ctx.ctxParam.env.cos = cos =Object.assign(cos,{
                        secretId:res.id,
                        secretKey:res.key
                    });
                    callback && callback(cos);
                });
            }
        };
        self.getUserSchool=(userId,roles,callback)=>{
            let param = {
                ids:userId,
            };
            if(roles){
                if('string' === typeof roles){
                    param.roles = roles;
                }else if(roles.length){
                    param.roles = roles.join(',');
                }
            }
            self.api.castle.school.user.getUserSchool(param,(res)=>{
                if(res.user && res.user.length){
                    let schoolUsers = Utils.getByPath(res.user[0],'_union.schoolUser');
                    if(schoolUsers && schoolUsers.length){
                        let schoolMap = Utils.arrayToMap(res.school,'_id');
                        let useSchoolUsers = schoolUsers.filter((u)=>{
                            if(u.isActived && (!u.role || u.role!=='student')){
                                u.school = schoolMap[u.schoolId];
                                return true;
                            }else{
                                return false;
                            }
                        })
                        let curSchoolUser = useSchoolUsers[0];
                        if(curSchoolUser){
                            callback && callback({data:{schoolUser:curSchoolUser,schoolUsers:useSchoolUsers}})
                        }else{
                            callback && callback(null);
                        }
                    }else{
                        callback && callback(null);
                    }
                }else if(res._notice||res._error){
                    callback && callback(res);
                }
            });
        }
        self.countLimit=(param,callback)=>{
            self.api.foundation.imei.countLimit(param,callback);
        };
        self.tts={
            getUrl:(param,callback)=>{
                self.api.tts.tts.getUrl(param,callback);
            }
        }
    }
}
ModuleService.displayName=displayName;
export default ModuleService