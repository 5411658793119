let modules=[
    // {
    //     title:'首页',
    //     moduleName:'home',
    //     menu:'home',
    //     router:['/'],
    //     component:(cb)=>{
    //         require.ensure([], (require) => {
    //             cb(require('../module/home/index').default)
    //         },'module/home')
    //     }
    // },
    {
        title:'班级',
        name:'classes',
        permission:'dashi_client_menu_class',
        router:['/classes','/classes/:key'],
        component:(cb)=>{
            import('../module/classes/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'内容',
        name:'content',
        permission:'dashi_client_menu_content',
        router:['/content','/content/:key'],
        component:(cb)=>{
            import('../module/content/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'设备',
        name:'device',
        permission:'dashi_client_menu_device',
        router:['/device','/device/:key'],
        component:(cb)=>{
            import('../module/device/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'任务',
        name:'mission',
        permission:'dashi_client_menu_task',
        router:['/mission','/mission/:key'],
        component:(cb)=>{
            import('../module/mission/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'课程',
        name:'course',
        permission:'dashi_client_menu_course',
        router:['/course','/course/:key'],
        component:(cb)=>{
            import('../module/course/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'资料',
        name:'means',
        permission:'dashi_client_menu_data',
        router:['/means','/means/:key'],
        component:(cb)=>{
            import('../module/means/index').then(({default: component}) => {
                cb(component)
            })
        }
    },{
        title:'运营',
        name:'product',
        permission:'dashi_client_menu_product',
        router:['/product','/product/:key'],
        component:(cb)=>{
            import('../module/product/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'系统',
        name:'system',
        permission:'dashi_client_menu_system',
        router:['/system','/system/:key'],
        component:(cb)=>{
            import('../module/system/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
    {
        title:'工具',
        name:'tools',
        permission:'dashi_client_menu_tools',
        router:['/tools','/tools/:key'],
        component:(cb)=>{
            import('../module/tools/index').then(({default: component}) => {
                cb(component)
            })
        }
    }

];
export default modules;