import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Utils from './common/core/Util';

import Login from './module/Login';
import ModuleRouter from './module/ModuleRouter';
import ModuleRouterTutor from './tutor_module/ModuleRouter';
import ModuleRouterDealer from './dealer_module/ModuleRouter';
import ModuleRouterOrg from './org_module/ModuleRouter';
import Api from './common/core/API';
import AppConf from './config/app_config';
import Context from './service/Context';
import AppGlobalAudioPlayer from '@com/app-global-audio-player';
import AppDrawerWraper from '@com/app-drawer-wraper';
import AppSideTipController from '@com/app-side-tip-controller';

import './css/app.css';
import './css/ui-dialog.css';
import './css/form.css';
import './css/btn.css';
import './css/tree.css';
import './css/iconfont/iconfont.css';
import './css/animate.css';

Utils.addCOSImageUploader(window.UE);
const serviceCache={};
let initContext = (self,ctxParam) => {
	let api = new Api(AppConf, ctxParam.env);
	let ctx = new Context(api);
	ctx.temp={};
    ctx.createService = function (ServiceClass){
        if(serviceCache[ServiceClass.displayName]){
            return serviceCache[ServiceClass.displayName];
        }else{
            return serviceCache[ServiceClass.displayName] = new ServiceClass(api);
        }
    };
    api.setGlobalError(function (err) {
        window.ctx.submitLock=false;
        if(err.request && err.request.status!==200){
            switch (err.request.status){
                case 404:
                    ctx.tip('请求资源不存在！');
                    break;
                case 401:
                    window.ctx.logout();
                    break;
                default:
                    ctx.tip('网络请求错误！')
            }
        }else{
            console.error(err);
        }
    });
	ctx.ctxParam = ctxParam;
	ctx.appConfig = AppConf;
    ctx.modal=function(option,ele){
        let containner=window.$("<div />").get(0);
        let modal;
        let d =window.dialog({
            title:option.title,
            skin:option.skin || 'dialog-modal',
            content:containner,
            okValue:option.okValue||'确定',
            cancelValue:option.cancelValue||'取消',
            ok:option.ok===false ? undefined : function(){
                if(option.ok && typeof option.ok === 'function'){
                    return option.ok(d,modal);
                }else{
                    return true;
                }
            },
            align: option.align,
            quickClose:option.quickClose || false,
            button: option.button,
            cancel:option.cancel === undefined ? true: function(){
                if(option.cancel) return option.cancel(d,modal)
            },
            cancelDisplay:option.cancelDisplay === undefined ? false : option.cancelDisplay,
            onshow:function(d){
                if(option.content){
                    modal= ReactDOM.render(option.content,containner);
                    if(modal) modal.dialog=d;
                }
                this.reset();
            },
            onclose:function(){
                option.onclose && option.onclose();
                setTimeout(function(){
                    containner.remove();
                },300)
            }
        });
        if(ele){
            d.show(ele);
        }else{
            d.showModal();
        }
        return d;
    };
    ctx.tip=function(content,ele,fn){
        let containner = window.$("<div />");
        let d =window.dialog({
            content:containner.html(content),
            align: 'right',
            padding:'15',
            skin:'dialog-tip',
            quickClose: !fn,
            onshow:function(){
                if(fn){
                    fn(d);
                }else{
                    !ele && setTimeout(function(){
                        d && d.close();
                    },3000);
                }
            },
            onclose:function(){
                setTimeout(function(){
                    containner.remove();
                },300)
            }
        });
        d.showModal(ele);
    };
    ctx.showImg = (url)=>{
        var img = new window.Image();
        img.onload=function(){
            let imgWidth = img.naturalWidth, imgheight  = img.naturalHeight;
            let showWidth,showHeight, rate = imgWidth/imgheight;
            let screenWidth =   window.innerWidth - 60;
            let screenHeight =  window.innerHeight - 60;
            switch(true){
                case rate>1 && imgWidth>screenWidth:
                showWidth = screenWidth;
                showHeight = showWidth/rate;
                break;
                case imgheight>screenHeight:
                rate = imgheight/screenHeight;
                showHeight = screenHeight;
                showWidth = showWidth/rate;
                break;
                default:
                    showHeight = imgheight;
                    showWidth = imgWidth;
            }
            if(showWidth>screenWidth){
                let rate = showWidth/screenWidth;
                showWidth = screenWidth;
                showHeight = showHeight/rate
            }
            if(showHeight>screenHeight){
                let rate = showWidth/screenWidth;
                showHeight = screenHeight;
                showWidth = showWidth/rate           
            }
            ctx.modal({
                content:<img alt={''} className="img" style={{width:showWidth, height:showHeight, verticalAlign:'middle' }} src={url}/>,
                title:  '',
                cancelDisplay: false,
                sureDisplay: false,
                canDrag: false,
                skin: 'pop-img',
            });
        }.bind(this);
        url && (img.src=url);
    };
    ctx.alert=function(msg,fun,cancel){
        let containner=window.$("<div/>");
        let d =window.dialog({
            title:"提示",
            content:containner.html(msg),
            okValue:'确定',
            skin:'dialog-alert',
            ok:function(){
                fun && fun(d);
            },
            cancel:!!cancel,
            cancelValue:'取消',
            onclose:function(){
                setTimeout(function(){
                    containner.remove();
                },300)
            }
        });
        d.showModal();
    };
    ctx.alertNotice=function(notice){
        let noticeObj;
        if(notice){
            if(typeof notice === 'object' && notice.length){
                noticeObj=notice[0];
            } else {
                noticeObj=notice;
            }
            let msg='错误:{code} {msg}';
            let msgObj= noticeObj.message || noticeObj.msg;
            let err={code:noticeObj.code};
            if(typeof msgObj ==='object'){
                err.msg=msgObj.name || msgObj.message;
            }else{
                err.msg=msgObj
            }
            ctx.alert(msg.format(err));
        }
    };
    ctx.openDrawer = function(module,onClose,drawerProps){
        self.appDrawerWraper && self.appDrawerWraper.open(module,onClose,drawerProps);
    };
    ctx.closeDrawer = function(clear){
        self.appDrawerWraper && self.appDrawerWraper.close(clear);
    };
	return ctx;
};

class App extends Component {
	constructor(props) {
		super(props);
		let querysObj = Utils.getSearchObj();
        let env = Utils.getRunningENV(querysObj.env,AppConf.apiInstance.env,AppConf.apiInstance.hosts);
        let app = Utils.getRunningApp(querysObj.app,querysObj.cooperation,AppConf.apiInstance.app,AppConf.apiInstance.channel);
        let isElectronDTQ = window.navigator.userAgent.toLowerCase().indexOf('electron/hmf_dtq') >= 0;
		let appCtxParam = {
            isElectronDTQ,
			env: env,
            app: app,
			querys: querysObj
		};
		this.service = initContext(this,appCtxParam);//初始化Context
        let self=this;
		if(window) {
			window.ctx = self.service ;
		}
        let token = querysObj.token || self.service.cookieToken();
        if(token){
            let header={
                'Authorization': 'Bearer ' + token
            };
            if(AppConf.apiInstance.header){
                Object.values(AppConf.apiInstance.header).forEach((headerItem)=>{
                    if(headerItem && typeof headerItem.key==='string' && typeof headerItem.value==='string'){
                        header[headerItem.key]=headerItem.value;
                    }
                })
            }
            self.service.api.setGlobalHeaders(header);
            self.state = {
                hasLogin:'checking',
                appCtxParam: appCtxParam
            };
        }else{
            self.state = {
                hasLogin:  false,
                appCtxParam: appCtxParam
            };
        }

        self.service.addEventListener(self.service.events.token_success, function(e) {
			if(e.data.token && e.data.token.length) {
			    let token = e.data.token[0];
                let loginUser = e.data.user[0];
                loginUser.role = e.data.role[0];
                self.service.loginUser = loginUser;
                self.service.api.setGlobalHeaders({
                    'Authorization': 'Bearer ' + token.token
                });
                self.getMyPermissions((permissionsMap)=>{
                    self.service.cookieToken(token.token,new Date(token.expireTime));
                    // let role=e.data.role ? e.data.role[0]:'';
                    let hasPermission= false;
                    let app=window.ctx.ctxParam.app;
                    if(permissionsMap && (permissionsMap[':super'] || permissionsMap[app.permission])){
                        hasPermission=true;
                    }
                    if(hasPermission){
                        self.setState({
                            hasLogin: true,
                            loginUser:  self.service.loginUser
                        });
                    }else{
                        window.ctx.cookieToken('');
                        window.ctx.alert("无登录权限，禁止登录!");
                        self.setState({
                            hasLogin: false,
                            loginUser: ''
                        })
                    }
                })
			}
		});
	}
	getMyPermissions(callback){
	    let self=this;
        self.service.getMyPermissions({},(res)=>{
	        if(res.permissions){
	            let permissionsMap={};
                res.permissions.forEach((code)=>{
                    permissionsMap[code]=1;
                });
                window.ctx.permissionsMap=permissionsMap;
                self.service.permissionsMap=permissionsMap;
	            callback && callback(permissionsMap);
            }else{
                window.ctx.cookieToken('');
                window.ctx.alert("无权限，禁止登录!");
                self.setState({
                    hasLogin: false,
                    loginUser: ''
                })
            }
        })
    }
	componentDidMount() {
        if(this.state.hasLogin==='checking'){
			this.getLoginUserBaseInfo();
        }
	}
	getLoginUserBaseInfo() {
	    let self=this;
        self.service.getUserBaseInfo({}, function (res) {
            if(res.user && res.user.length){
            	let loginUser=res.user[0];
                loginUser.role=res.role[0];
                self.service.loginUser=loginUser;
                // let role=res.role ? res.role[0]:'';
                self.getMyPermissions((permissionsMap)=>{
                    let hasPermission= false;
                    let app=window.ctx.ctxParam.app;
                    if(permissionsMap && (permissionsMap[':super'] || permissionsMap[app.permission])){
                        hasPermission=true;
                    }
                    if(hasPermission){
                        self.setState({
                            hasLogin: true,
                            loginUser:  self.service.loginUser
                        },window.ctx.getCosConfig);
                    }else{
                        window.ctx.cookieToken('');
                        window.ctx.alert("当前账号无登录权限!");
                        self.setState({
                            hasLogin: false,
                            loginUser: ''
                        })
                    }
                })
			}else{
                window.ctx.cookieToken('');
                window.ctx.alert("当前账号无登录权限!");
                self.setState({
                    hasLogin: false,
                    loginUser: ''
                })
            }
        });
    }
    componentDidCatch(error, errorInfo) {
        console.error(error);
        console.error(errorInfo);
	}

    render() {
	    let self=this;
		let view;
        let app = window.ctx.ctxParam.app;
		if(this.state.hasLogin === true) {
		    if(app.appKey==='tutor'){
			    view = <ModuleRouterTutor parent={this} loginUser={this.state.loginUser}/>;
            }else if(app.appKey==='dealer'){
			    view = <ModuleRouterDealer parent={this} loginUser={this.state.loginUser}/>;
                // https://partner.huimanfen.com/
            }else if(app.appKey==='org'){
			    view = <ModuleRouterOrg parent={this} loginUser={this.state.loginUser}/>;
            }else{
			    view = <ModuleRouter parent={this} loginUser={this.state.loginUser}/>;
            }
		} else if(this.state.hasLogin === false) {
			view = <Login parent={this} />;
		}else{
            view=<div className="loading-wrap">
                <div>
                    <img alt={''} src="/assets/img/loading.gif"/>
                    <p>努力加载中......</p>
                </div></div>
		}
		return <div className="App">
            {view}
            <AppDrawerWraper ref={(el)=>{self.appDrawerWraper=el;}}/>
            <AppGlobalAudioPlayer ref={(el)=>{window.ctx.audioPlayer=el;}}/>
            <AppSideTipController ref={(el)=>{window.ctx.sideTiper=el;}}/>
		</div>
	}
}
export default App;