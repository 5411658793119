import React, {Component} from 'react';
import cx from 'classnames';
class Module extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user:props.user ||'',
            schoolUser:props.schoolUser,
            school:props.school,
        }
    }
    logout(){
        window.ctx.logout();
    }
    render() {
        let self=this;
        let {schoolUser,user} = this.state;
        return(
            <div className={cx("module-account-menu",this.props.className)} >
                {
                    schoolUser?
                    <div className="menu-label"><label className="text-label">你好,<b>{schoolUser.name}</b>！</label><i className="icon iconfont menu-label-icon"></i></div>
                    :<div className="menu-label"><label className="text-label">你好,<b>{user.name}</b>！</label><i className="icon iconfont menu-label-icon"></i></div>
                }
                <div className="sub-menu-wrap trans">
                    <ul className="sub-menu-list">
                        <li className="sub-menu-item"><a className="text-label" onClick={self.logout}>安全退出</a></li>
                    </ul>
                </div>
            </div>
        )
    }
}
export default Module
