import React, {Component} from 'react';
import {Router} from '../common/core/director';
import Modules from '../config/modulesTutor';
import AppNavHeader from '../module/component/app_header/AppNavHeader';
import AppPermissionValidate from "../module/component/AppPermissionValidate";

class Module extends Component {
    constructor(props) {
        super(props);
        let map={};
        Modules.forEach((c) =>{
            map[c.name]=c;
        });

        this.state = {
            loginUser:props.loginUser,
            moduleMap:map,
            moduleList:Modules,
            currentModuleComponent:'',
            currentModuleConfig:'',
            currentModuleParams:''
        };

        this.getUserSchool = this.getUserSchool.bind(this);
        this.changeUser = this.changeUser.bind(this);
        this.initRouter = this.initRouter.bind(this);
        this.changeRouter = this.changeRouter.bind(this);
    }
    componentDidMount() {
        this.getUserSchool(()=>{
            this.initRouter();
        });
    }
     initRouter=()=>{
         let self=this;
         self.router=new Router();
         let modules=this.state.moduleList;
         let indexModule=modules[0];
         //注册默认路由
         self.router.configure({
             notfound:function(...param){
                 self.changeRouter(indexModule,param);
             },
             async: true
         });
         modules.forEach((m) =>{
             if(typeof m.router ==='object' && m.router.length){
                 m.router.forEach(function (mitem) {
                     self.router.on(mitem,(...param) =>{
                         self.changeRouter(m,param);
                     });
                 })
             }else{
                 self.router.on(m.router,(...param) =>{
                     self.changeRouter(m,param);
                 });
             }
         })
         self.router.init('/');
     }
     changeRouter =(module,param) =>{
         let self=this;
         if(module){
             let params=[];
             if(param && param.length){
                 params=param.filter(function (p) {
                     return typeof p==='string'? true:false;
                 })
             }
             self.loadModule(module,params);
         }
     }
     loadModule=(module,params)=>{
         let self=this;
         module.component((moduleComponent) => {
             // console.dir(module)
             //console.log(params)
             self.setState({
                 currentModuleComponent:moduleComponent,
                 currentModuleConfig:module,
                 currentModuleParams:params
             })
         })
     }
     changeUser(schoolUser,schoolUsers,callback){
        let self=this;
         window.ctx.schoolUser=schoolUser;
         window.ctx.school=schoolUser.school;
         self.setState({
             schoolUser,schoolUsers
         },callback)
     }
    getUserSchool(callback){
        let self=this;
        let {loginUser} = self.state;
        window.ctx.getUserSchool(loginUser._id,['teacher'],(res)=>{
            if(res && res.data){
                let {schoolUser,schoolUsers} = res.data;
                let useSchoolUsers = [];
                let useSchoolUser;
                if(schoolUsers && schoolUsers.length){
                    schoolUsers.forEach((su)=>{
                        if(su.school && !su.school.orgId){
                            useSchoolUsers.push(su);
                        }
                    })
                    useSchoolUser=useSchoolUsers[0];
                }
                if(useSchoolUser){
                    self.changeUser(useSchoolUser,useSchoolUsers,callback)
                }else{
                    window.ctx.alert('禁止使用：当前用户不属于任何学校，请联系管理员或使用其他账号进行登录！',()=>{
                        window.ctx.logout();
                    },false);
                }
            }else{
                window.ctx.logout();
            }
        })
    }
    handleSchoolUserChange(schoolUser){
        let self=this;
        self.changeUser(schoolUser,self.state.schoolUsers);
    }
    render() {
        let self=this;
        let state= self.state;
        let {schoolUser,schoolUsers} = state;
        let C = state.currentModuleComponent;
        return schoolUser && C ?
            <div className="module-paranel app-layout-wrap tutor-layout">
            <div className="app-layout-header"><AppNavHeader key={schoolUser._id} parent={self}
                                                      moduleConfig={state.currentModuleConfig}
                                                      moduleParams={state.currentModuleParams}
                                                      loginUser={state.loginUser}
                                                     schoolUser={schoolUser}
                                                     schoolUsers={schoolUsers}
                                                     onSchoolUserChange={self.handleSchoolUserChange.bind(self)}
            /></div>
            <div className="app-layout-module-content">
            <AppPermissionValidate key={`${schoolUser._id}_${state.currentModuleConfig.name}`} permission={state.currentModuleConfig.permission}>
                <C key={`${schoolUser._id}_${state.currentModuleConfig.name}`} parent={self}
                                            moduleConfig={state.currentModuleConfig}
                                            moduleParams={state.currentModuleParams}
                                            loginUser={state.loginUser}
                                           schoolUser={schoolUser}
                                           school={schoolUser.school}
                />
            </AppPermissionValidate>
            </div>
            </div>
        :null
        }
}
export default Module