import COS from 'cos-js-sdk-v5';
import { MD5 } from 'crypto-js';
export const fileTypes={
    image:{
        accept:['image/jpeg','image/jpg','image/gif','image/png'],
        dirKey:'image',
        hostFix:'file.myqcloud.com'
    },
    audio:{
        accept:['audio/mpeg','audio/webm','audio/ogg'],
        dirKey:'audio',
        hostFix:'file.myqcloud.com'
    },
    video:{
        accept:['video/mp4','vidio/webm','vidio/ogg'],
        dirKey:'video',
        hostFix:'file.myqcloud.com'
    },
    ppt:{
        accept:['application/vnd.openxmlformats-officedocument.presentationml.presentation','application/vnd.ms-powerpoint'],
        dirKey:'ppt',
        hostFix:'file.myqcloud.com'
    }
}

if(!String.prototype.format){
    String.prototype.format= function(kvObj){
        var args = arguments;
        var result=this;
        if(args.length>0){
            if(arguments.length===1 && typeof(kvObj)==="object"){
                for(let key in kvObj){
                    let reg=new RegExp("({"+key+"})","g");
                    result=result.replace(reg, kvObj[key]);
                }
            }else{
                result= this.replace(/\{(\d+)\}/g,function(s,i){
                    return args[i];
                });
            }
        }
        return result;
    };
}
// Warn if overriding existing method
if(Array.prototype.equals)
    console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
// attach the .equals method to Array's prototype to call it on any array
Array.prototype.equals = function (array) {
    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time
    if (this.length !== array.length)
        return false;

    for (let i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i]))
                return false;
        }
        else if (this[i] !== array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", {enumerable: false});

const Utils ={
    copyText : (text)=>{
        let input = document.createElement('input');
        input.setAttribute('readonly', 'readonly');
        document.body.appendChild(input);
        input.setAttribute('value', text);
        input.select();
        if (document.execCommand) {
            document.execCommand('copy');
            window.ctx.tip('复制成功！')
        }
        document.body.removeChild(input);
    },
    replace:{
        zhToEN:(text)=>{
            let t=[];
            let conf = {
                "’":"'",
                "‘":"'",
                "。":".",
                "，":",",
                "？":"?",
                "：":":",
                "；":";",
                "！":"!",
                "”":'',
                "“":'',
                "（":'(',
                "）":')',
                "【":'[',
                "】":']',
                "《":"'",
                "》":"'",
            }
            if('string' === typeof text && text.length){
                for(let i in text){
                    if(!isNaN(i)){
                        let c = text.charCodeAt(i);
                        let cStr = String.fromCharCode(c);
                        if(conf[cStr]){
                            t.push(conf[cStr]);
                        }else if(c===34){
                            t.push(conf['']);
                        }else{
                            t.push(cStr);
                        }
                    }
                }
            }
            return t.join('');
        }
    },
    validate:{
        checkLength:(value,min=0,max=Number.MAX_SAFE_INTEGER)=>{
            return !!value && value.length >= min && value.length <= max
        },
        isPassword:(str)=>{
            return new RegExp(/^[a-zA-Z_0-9]{6,20}$/).test(str);
        },
        isPhone:(str,len=11)=> {
            if(str && str.length===len){
                return new RegExp(/^1[3-9][0-9]\d{4,8}$/).test(str);
            }else{
                return false;
            }
        },
        isEmail:(str)=> {
            return new RegExp(/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/).test(str);
        },
        isNotEmpty:(value)=>{
            if(value===undefined || value===null){
                return false
            }else{
                let type= typeof value;
                if(type==='string'){
                    return !!value.length
                }else if(type ==='number'){
                    return true
                }else if(type === 'object'){
                    return value.length!==undefined? !!value.length:!!value.keys().length;
                }else{
                    return !!value;
                }
            }
        }
    },
    file:{
      isAllowImage:(file)=>{
          let flag = false;
          if(file){
              let {type,name} = file;
              if(type){
                  if(fileTypes.image.accept.indexOf(type)>=0){
                      flag=true;
                  }
              }else if(name){
                let extIndex = name.lastIndexOf('.');
                if(extIndex>=0){
                    let ext = name.substr(extIndex+1);
                    switch (ext.toLowerCase()) {
                        case 'png':
                        case 'jpg':
                        case 'gif':
                        case 'jpeg':
                        case 'ppt'://pptx、ppt、pot、potx、pps、ppsx、dps、dpt、pptm、potm、ppsm。
                        case 'pptx':
                            flag=true;
                            break;
                        default:
                    }
                }
              }
          }
          return flag;
      },
      isAllowAudio:(file)=>{
          let flag = false;
          if(file){
              let {type,name} = file;
              if(type){
                  if(fileTypes.audio.accept.indexOf(type)>=0){
                      flag=true;
                  }
              }else if(name){
                  let extIndex = name.lastIndexOf('.');
                  if(extIndex>=0){
                      let ext = name.substr(extIndex+1);
                      switch (ext.toLowerCase()) {
                          case 'mp3':
                              flag=true;
                              break;
                          default:
                      }
                  }
              }
          }
          return flag;
      },
      isAllowVideo:(file)=>{
          let flag = false;
          if(file){
              let {type,name} = file;
              if(type){
                  if(fileTypes.video.accept.indexOf(type)>=0){
                      flag=true;
                  }
              }else if(name){
                  let extIndex = name.lastIndexOf('.');
                  if(extIndex>=0){
                      let ext = name.substr(extIndex+1);
                      switch (ext.toLowerCase()) {
                          case 'mp4':
                              flag=true;
                              break;
                          default:
                      }
                  }
              }
          }
          return flag;
      },
      isAllowPPT:(file)=>{
          let flag = false;
          if(file){
              let {type,name} = file;
              if(type){
                  if(fileTypes.ppt.accept.indexOf(type)>=0){
                      flag=true;
                  }
              }else if(name){
                  let extIndex = name.lastIndexOf('.');
                  if(extIndex>=0){
                      let ext = name.substr(extIndex+1);
                      switch (ext.toLowerCase()) {
                          case 'pptx':
                          case 'ppt':
                              flag=true;
                              break;
                          default:
                      }
                  }
              }
          }
          return flag;
      },
    },
    /**
     * @a:Aarry
     * @b:Aarry
     * @key: undefined or String/Number/(another type to can be key of Object) or Function
     * @key=undefined => equals like ['a1'] === ['b1']
     * @key=String/Number => equals like [{a_key:123}] === [{b_key:123}] and support key2 to Arayy b
     * @key=Function => equals like key(a,b) must return a true or false value
     * */
    equalsObjectArrays(a,b,key,key2){
        if(typeof a === 'object' &&  typeof b === 'object'){
            if(a.length !== b.length) {
                return false
            }
            if(a.length===0 && b.length===0) {
                return false
            }
            let len= a.length;
            let flag=true;
            if(key!==undefined){
                if(typeof key === 'function'){
                    for(let i=0;i<len;i++){
                        if(!key(a[i],b[i])){
                            flag=false;
                            break;
                        }
                    }
                }else{
                    for(let i=0;i<len;i++){
                        if(a[i][key]!==b[i][key2 || key]){
                            flag=false;
                            break;
                        }
                    }
                }
            }else {
                for(let i=0;i<len;i++){
                    if(a[i]!==b[i]){
                        flag=false;
                        break;
                    }
                }
            }
            return flag;
        }else{
            return false;
        }
    },
    clone(targetObject) {
        let objClone=function (obj) {
            // Handle the 3 simple types, and null or undefined or function
            if (null == obj || "object" !== typeof obj) return obj;
            let copy;
            // Handle Date
            if (obj instanceof Date) {
                copy = new Date();
                copy.setTime(obj.getTime());
                return copy;
            }
            // Handle Array or Object
            if (obj instanceof Array | obj instanceof Object) {
                copy = (obj instanceof Array)?[]:{};
                for (let attr in obj) {
                    if (obj.hasOwnProperty(attr))
                        copy[attr] = objClone(obj[attr]);
                }
                return copy;
            }
            throw new Error("Unable to clone obj! Its type isn't supported.");
        }
        return objClone(targetObject);
    },
    objectWalk(treeObj,childsKey,callback){
        if(typeof treeObj !== 'object'){
            throw new Error('object walk need a object to walk');
        }
        let tf = typeof childsKey;
        if(!childsKey || !(tf === 'string' || tf === 'function')){
            throw new Error('object walk need a string or function childsKey to walk');
        }
        if(typeof callback !== 'function'){
            throw new Error('object walk need a callback function');
        }
        let walk = function (obj,key,parents,depth,index,cb) {
            let child;
            if(typeof key==='function'){
                child=key(obj);
            }else{
                child=obj[key];
            }
            if(obj && cb(obj,parents,depth,index)!==false){
                let childParents=parents.slice(0);
                let childDepth=depth+1;
                childParents.push(obj);
                if(typeof child ==='object' && !isNaN(child.length)){
                    let len=child.length;
                    for(let i=0;i<len;i++){
                        if(walk(child[i],key,childParents,childDepth,i,cb)===false){
                            break;
                        }
                    }
                }else if(child){
                    walk(child,key,childParents,childDepth,0,cb);
                }
            }else{
                return false;
            }
        };
        walk(treeObj,childsKey,[],0,0,callback);
    },
    calcAnswerTime(diffcult){
      return Math.round((1+((10-diffcult)/2))*120);
    },
    arrayToMap(array,key){
        let map={};
        let keyType=typeof key;
        if(typeof array==='object' && array.length!==undefined && (keyType==='string' || keyType==='function')){
            if(keyType==="function"){
                array.forEach((item)=>{
                    if(item){
                        map[key(item)]=item
                    }
                });
            }else{
                array.forEach((item)=>{
                    if(item){
                        map[item[key]]=item
                    }
                });
            }
        }
        return map;
    },
    hashCode(str){
        let hash = 0;
        if(typeof str==='string' && str) {
            for (let i = 0; i < str.length; i++) {
                hash = hash * 31 + str.charCodeAt(i);
                hash = this.intValue(hash);
            }
        }
        return hash;
    },
    shuffle(arr) {
        let i = arr.length;
        let [ ...cloneArr ] = arr
        while (i) {
            let j = Math.floor(Math.random() * i--);
            [cloneArr[j], cloneArr[i]] = [cloneArr[i], cloneArr[j]];
        }
        return cloneArr;
    },
    randomNum:(Min,Max)=>{
        let Range = Max - Min;
        let Rand = Math.random();
        let num = Min + Math.round(Rand * Range);
        return num;
    },
    randomNums:(min,max,count=1)=>{
        let nums=[];
        let num;
        do {
            num = Utils.randomNum(min,max);
            if(nums.indexOf(num) < 0){
                nums.push(num);
            }
        }while (nums.length<count)
        return nums;
    },
    getQueryByName(name,url){
        let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
        let str=url || window.location.hash || window.location.search;
        str=str.substr(str.indexOf('?')+1);
        let r = str.match(reg);
        if(r!=null)return  unescape(r[2]); return null;
    },
    getRunningApp(queryAppKey,queryChannelKey,appConfig,channelCoinfig){
        let app = undefined;
        if(queryAppKey){
            app = appConfig[queryAppKey];
        }else{
            let {hostname} = window.location;
            Object.keys(appConfig).forEach((key)=>{
                if(hostname.indexOf(key+'.')>=0){
                    app = appConfig[key];
                    queryAppKey=key;
                }
            })
        }
        if(queryChannelKey && channelCoinfig && channelCoinfig[queryAppKey] && channelCoinfig[queryAppKey][queryChannelKey]){
            app=Object.assign({},app,channelCoinfig[queryAppKey][queryChannelKey]);
        }
        return app ||  appConfig['dsm'];
    },
    getRunningENV(queryEnvKey,envConfig,hosts) {
        let env;
        let hostEnvKey;
        if(queryEnvKey && envConfig[queryEnvKey]){
            hostEnvKey = queryEnvKey;
        }else{
            let port=window.location.port;
            let host=window.location.hostname;
            if(hosts){
                if(hosts[host]){
                    hostEnvKey = hosts[host];
                }
            }
            if(!hostEnvKey){
                if(port){
                    hostEnvKey = 'dev';
                }else if(host.indexOf('dev.')===0){
                    hostEnvKey = 'dev';
                }else if(host.indexOf('test.')===0){
                    hostEnvKey = 'test';
                }else if(host.indexOf('pre.')===0){
                    hostEnvKey = 'pre';
                }else{
                    hostEnvKey = 'prod';
                }
            }
        }
        env = envConfig[hostEnvKey];
        env.envKey = hostEnvKey;
        return env;
    },
    getByPath(val, path) {
        if('object' !== typeof val){
            return undefined;
        }
        if ( 'string' === typeof path) {
            path = path.split('.');
        }
        let key = path.shift();
        let sub = val[key]!==undefined ? val[key] : undefined;
        if (sub === undefined) {
            return sub;
        }
        if (path.length) {
            return Utils.getByPath(sub, path);
        }
        return sub;
    },
    getSearchObj(searchStr){
        let qs=searchStr || window.location.search || window.location.hash;
            qs=decodeURIComponent(qs.substr(qs.indexOf('?')+1));
        let args = {};
        let items = qs.length>0 ? qs.split('&'):[];
        let item = null,name = null,value = null,len = items.length;
        for(let i = 0;i < len; i++){
            item = items[i].split('=');
            name = decodeURIComponent(item[0]);
            value = decodeURIComponent(item[1]);
            if(name.length){
                args[name] = value;
            }
        }
        return args;
    },
    getUrlByObj(obj){
        let query = [];
        for(let key in obj){
            if(obj[key] === undefined || !''.concat(obj[key]).length) continue
            query.push(''.concat(key,'=',obj[key])) 
        }
        return query.join('&')
    },
    formatMoney(s, n) {
        n = n > 0 && n <= 20 ? n : 2;
        s = parseFloat((s + "").replace(/[^\d.-]/g, "")).toFixed(n) + "";
        let l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
        let t = "";
        for (let i = 0; i < l.length; i++) {
            t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? "," : "");
        }
        return t.split("").reverse().join("") + "." + r;
    },
    MultNet(service, params, callback){
        let checkLoaded = function(arr){
            arr = arr || [];
            var _flag = true;
            arr.forEach(function(item){if(item && !item.res) _flag = false; })
            return _flag
        }
        let dataMap = {};
        [].concat(params || []).forEach(function(item){
            item && Utils.getKeyData(service, item.name, function(){})(item.param, function(res){
                item.res = res;
                if(item.stateName) dataMap[item.stateName] = res;
                if(checkLoaded(params) && callback ) callback({state: dataMap, source: params});
            }.bind(this), function(res){
                item.res = res || {};
                if(item.stateName) dataMap[item.stateName] = res || {};
                if(checkLoaded(params) && callback ) callback({state: dataMap, source: params});
            });
            setTimeout(function(){
                if(!checkLoaded([item])) item.res = {_error: '请求超时'};
            }.bind(this), 16000)
        }.bind(this));
        setTimeout(function(){
            if(!checkLoaded(params) && callback) callback({state: dataMap, source: params});
        }.bind(this), 25000)
    },
    bytesToSize(bytes) {
        if (bytes === 0) return '0 B';
        let k = 1024;
        let sizes = ['B','KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        let i = Math.floor(Math.log(bytes) / Math.log(k))
        //return (bytes / Math.pow(k, i)) + ' ' + sizes[i];
        return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
        //toPrecision(3) 后面保留两位小数，如1.00GB
    },
    formaTime(s){
        var t;
        if(s > -1){
            var hour = Math.floor(s/3600);
            var min = Math.floor(s/60) % 60;
            var sec = s % 60;
            if(hour>0){
                if(hour < 10) {
                    t = '0'+ hour + " 时 ";
                } else {
                    t = hour + " 时 ";
                }
            }
            if(min)
            if(min < 10){t += "0";}
            t += min + " 分 ";
            if(sec < 10){t += "0";}
            t += sec+ " 秒 ";
        }
        return t;
    },
    formatSeconds(s){
        let t=[];
        if(s > -1){
            var hour = Math.floor(s/3600);
            var min = Math.floor((s-hour*3600)/60);
            var sec = s-hour*3600-min*60;
            if(hour>0){
                if(hour < 10) {
                    t = ['0',hour,' 时 '];
                } else {
                    t = [hour,' 时 '];
                }
            }
            if(min < 10) {
                t = t.concat(['0',min,' 分 ']) ;
            }else{
                t = t.concat([min,' 分 ']);
            }

            if(sec < 10){
                t = t.concat(['0',sec,' 秒 ']) ;
            }else{
                t = t.concat([sec,' 秒 ']);
            }
        }
        return t.join('');
    },
    addCOSImageUploader(UE){
        if(UE && UE.registerUI){
            UE.registerUI('uploadCOSImage', function(editor, uiName) {
                //创建一个button
                let btn = new UE.ui.Button({
                    //按钮的名字
                    name: uiName,
                    //提示
                    title: '上传图片',
                    //添加额外样式,指定icon图标,这里默认使用一个重复的icon
                    cssRules: 'background-position: -380px 0;',
                    //点击时执行的命令
                    onclick: function() {
                        //这里可以不用执行命令,做你自己的操作也可
                        window.ctx.getCosConfig((COS_ENV)=>{
                            let cos= new COS({
                                SecretId:COS_ENV.secretId,
                                SecretKey:COS_ENV.secretKey
                            });
                            let fileInput=document.createElement('input');
                            fileInput.setAttribute('type','file');
                            fileInput.setAttribute('class','ueditor-cos-file-input');
                            fileInput.setAttribute('accept','image/jpeg,image/jpg,image/png,image/gif');
                            fileInput.onchange=function () {
                                if(fileInput.files.length){
                                    let file=fileInput.files[0];
                                    let t = Date.now();
                                    let extName=file.name.substr(file.name.lastIndexOf('.'));
                                    let key=[
                                        COS_ENV.serverKey,
                                        window.ctx.loginUser._id,
                                        fileTypes.image.dirKey,
                                        MD5(`${file.name.trim()}_${t}`)+extName
                                    ].join('/');
                                    cos.putObject({
                                        Bucket: COS_ENV.bucket, /* 必须 */
                                        Region: COS_ENV.region,    /* 必须 */
                                        Key:key,              /* 必须 */
                                        StorageClass: 'STANDARD',
                                        Body: file // 上传文件对象

                                    }, function(err, data) {
                                        if(!err && data.statusCode===200){
                                            let imgUrl='https://'+COS_ENV.bucket+'.'+fileTypes.image.hostFix+'/'+encodeURI(key);
                                            editor.execCommand( 'insertimage', {
                                                src:imgUrl
                                            } );
                                        }else{
                                            window.ctx.tip('上传图片失败！')
                                        }
                                    });
                                }
                            };
                            fileInput.click();
                        });

                    }
                });
                return btn;
            });

        }
    },
    toFixed(num, fixedNumber){
        switch (true) {
            case num == undefined:
            case num == false:
            case num == Infinity:
                num = 0;
            break;
        }
        return Number(num).toFixed(fixedNumber);
    },
    getKeyData (source={}, keys='', defValue, unit='.'){
        let tempResult = [].concat(source || {}).map(function(sourceItem){
            switch(Utils.getType(keys)){
                case 'object':
                    let tempInfo = {}
                    for(let i in keys){
                        tempInfo[i] = Utils.getKeyData(sourceItem, keys[i], defValue, unit)
                    }
                    return tempInfo
                case 'array':
                    return Utils.getKeyData(sourceItem, keys.join(unit), defValue, unit);
                case 'string':
                    let tempSource = sourceItem;
                    let tempKeys = keys.split('.');
                    try{
                        tempKeys.forEach(function(keyItem, index){
                            if(keyItem=='[]'){ 
                                let packKey =  tempKeys.slice(index+1).join(unit);
                                tempSource = !packKey ? tempSource: Utils.getKeyData(tempSource, packKey, defValue, unit);
                                throw new Error('isWrong'); 
                            }
                            tempSource = tempSource[keyItem];
                            switch(true){
                                case (index+1) < tempKeys.length && ["number","string"].indexOf(Utils.getType(tempSource))>-1:
                                case tempSource== undefined:
                                tempSource = defValue;
                                throw new Error('isWrong');
                            }
                        })
                    }catch(e){}
                    return tempSource
            }
        })
        return Utils.getType(source)=='array'? tempResult : tempResult.shift()
    },
    getConfigMap(arr, config, keyFormat, dataFormat, isMult){
        let keyList = [].concat(config || []);
        let keysMap = {};
        let parseData =  function(data, map, itemKey, keyFormat, Dataformat, isMult){
            let tempData = data;
            if(!map[itemKey]) map[itemKey] = {}
            let tempMap = map[itemKey];
            let tempKey = keyFormat ? keyFormat(data, itemKey):Utils.getKeyData(data, itemKey,'');
            [].concat(tempKey).map(function(itemKey){
                if(Dataformat) tempData = Dataformat(data);
                Utils.setKeyData(tempMap, itemKey, tempData, isMult);
            })
        };
        [].concat(arr || []).forEach(function(item, index){
            keyList.forEach(function(keyItem){
                switch (Utils.getType(keyItem)) {
                    case 'string':
                    return parseData(item, keysMap, keyItem, keyFormat, dataFormat, isMult)
                    case 'object':
                    return parseData(item, keysMap, keyItem.key, keyItem.keyFormat || keyFormat, keyItem.dataFormat || dataFormat, keyItem.isMult || isMult)
                }
            })
        })
        return keysMap
    },
    getPackClass(arr, config){
        if(!config) return this;
        let tempMap = {classMap: {}, classDatas: {}};
        let Tool_PackData = function(data, config){
            var nodeParam = {isValid:false}
            for(let i in config){
                if(config[i].validate(data)) {
                    nodeParam.isValid = true;
                    nodeParam.data = {
                        key:    i,
                        source: data,
                        config: config[i],
                        id:     config[i].id(data),
                    };
                    if(config[i].childs) nodeParam.data.childs = [].concat(config[i].childs(data) || []);
                    break;
                };
            }
            return nodeParam
        };
        let tempCycle = function(arr, obj, config, parent){
            arr.forEach(function(item, index){
                let itemNode = Tool_PackData(item, config);
                if(!itemNode.isValid) return
                if(parent && parent.id) itemNode.data.tempParentId = parent.id
                if(parent){
                    itemNode.data.parentId      = parent.id;
                    itemNode.data.parentKey     = parent.key;
                    itemNode.data.parentSource  = parent
                }
                Utils.setKeyData(obj.classMap, [itemNode.data.key, itemNode.data.id].join('.'), itemNode.data);
                Utils.setKeyData(obj.classMap, ['all', itemNode.data.id].join('.'), itemNode.data);
                Utils.setKeyData(obj.classDatas, itemNode.data.key, item, true);
                if(itemNode.data.childs) tempCycle(itemNode.data.childs, obj, config, itemNode.data)
            })
        };
        tempCycle(arr, tempMap, config)
        return tempMap
    },
    getType(source) {
        return Object.prototype.toString.call(source).slice(8,-1).toLowerCase();
    },
    setKeyData(source, keys, value, isMult=false){
        let temp = source;
        keys = ''.concat(keys || '').split('.');
        keys.forEach(function(key, index){
            if(!key) return;
            if(index+1 == keys.length) {
                if(!isMult) return temp[key] = value
                temp[key] = [].concat(temp[key] || [], value)
            }
            if(!temp[key]) temp[key] = {}
            temp = temp[key]
        });
        return temp
    },
    string:{
        toUnicode:function(str){
              return unescape(str);
        },
        lastInsertOf:function (str,num,str1) {
            let a=[];
            let b=[];
            let len = str.length;
            for(let i=len-1;i>=0;i--){
                if(len-i<=num){
                    b.unshift(str.charAt(i));
                }else{
                    a.unshift(str.charAt(i));
                }
            }
            return a.concat(str1).concat(b).join('');
        },
        innsertOf:function (str,index,str1) {
            let a=[];
            let b=[];
            let len = str.length;
            for(let i=0;i<len;i++){
                if(i>index){
                    b.push(str.charAt(i));
                }else{
                    a.push(str.charAt(i));
                }
            }
            return a.concat(str1).concat(b).join('');
}
    },
    zeroFill(num){
        return (num > 9 ? '' : '0').concat(num);
    },
    readSentences(sentences){
        let rows = [];
        let row = [];
        sentences && sentences.forEach(function (w, i) {
            let overall = w.overall
            let sentence = w.sentence
            let lastChartIndex = sentence.lastIndexOf('..');
            if (lastChartIndex >= sentence.length - 3) {
                row.push({ class: Utils.fontProcess(overall), name: sentence.substr(0, lastChartIndex) })
                rows.push(row);
                row = [];
            } else {
                row.push({ class: Utils.fontProcess(overall), name: sentence })
            }
        })
        return rows
    },
    fontProcess(overall){
        if (overall >= 80) {
            return 'level-color-you'
        } else if (overall >= 60) {
            return 'level-color-liang'
        } else if (overall >= 30) {
            return 'level-color-zhong'
        } else {
            return 'level-color-cha'
        }
    }
}

export default Utils