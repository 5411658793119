import React, {Component} from 'react';
import cx from 'classnames';
import AppModuleLink from '@com/AppModuleLink';
import AppAccountMenu from '../AppAccountMenu';
import AppPermissionValidate from '@com/AppPermissionValidate';
import AppHeadSchoolUserSelector from '../school-selector';

import logo from '@img/AppImg/logo_org.png';
import './index.css';

const APP_TOP_NAVS = {
    'org_manager':[
        {
            title:'机构',
            name:'my_org',
            // permission:'dashi_client_menu_tutor_tools',
            router:'/my_org'
        },
        {
            title:'内容',
            name:'org_content',
            // permission:'dashi_client_menu_tutor_homework',
            router:'/org_content'
        },
    ],
    'org_teacher':[
        {
            title:'作业',
            name:'tutor_homework',
            // permission:'dashi_client_menu_tutor_homework',
            router:'/tutor_homework'
        },
        {
            title:'互动课堂',
            name:'tutor_hudong',
            // permission:'dashi_client_menu_tutor_homework',
            router:'/tutor_hudong'
        },
        {
            title:'班级',
            name:'tutor_classes',
            // permission:'dashi_client_menu_tutor_homework',
            router:'/tutor_classes'
        },
        // {
        //     title:'考试',
        //     name:'tutor_exam',
        //     // permission:'dashi_client_menu_tutor_exam',
        //     router:'/tutor_exam'
        // },
        {
            title:'单词速记',
            name:'word_shorthand',
            // permission:'dashi_client_menu_tutor_homework',
            router:'/word_shorthand'
        },
        {
            title:'工具',
            name:'tutor_tools',
            // permission:'dashi_client_menu_tutor_tools',
            router:'/tutor_tools'
        },
    ],
    'teacher':[
        {
            title:'内容',
            name:'tutor_content',
            // permission:'dashi_client_menu_tutor_homework',
            router:'/tutor_content'
        },
        {
            title:'作业',
            name:'tutor_homework',
            // permission:'dashi_client_menu_tutor_homework',
            router:'/tutor_homework'
        },
        {
            title:'互动课堂',
            name:'tutor_hudong',
            // permission:'dashi_client_menu_tutor_homework',
            router:'/tutor_hudong'
        },
        {
            title:'单词速记',
            name:'word_shorthand',
            // permission:'dashi_client_menu_tutor_homework',
            router:'/word_shorthand'
        },
        {
            title:'班级',
            name:'tutor_classes',
            // permission:'dashi_client_menu_tutor_homework',
            router:'/tutor_classes'
        },
        // {
        //     title:'考试',
        //     name:'tutor_exam',
        //     // permission:'dashi_client_menu_tutor_exam',
        //     router:'/tutor_exam'
        // },
        {
            title:'工具',
            name:'tutor_tools',
            // permission:'dashi_client_menu_tutor_tools',
            router:'/tutor_tools'
        },
    ]
};
class Module extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginUser:props.loginUser,
            org:props.org,
            schoolUser:props.schoolUser,
            school:props.school,
            schoolUsers:props.schoolUsers,
            moduleConfig:props.moduleConfig,
            moduleParams:props.moduleParams
        }
    }
    componentWillReceiveProps(props) {
        //console.log(props.moduleConfig)
        this.setState({
            moduleConfig:props.moduleConfig,
            moduleParams:props.moduleParams
        })
    }
    componentDidMount() {}
    handleSchoolUserChange(schoolUser){
        let self=this;
        self.setState({
            schoolUser
        },()=>{
            self.props.onSchoolUserChange && self.props.onSchoolUserChange(schoolUser)
        })
    }
    render() {
        let self=this;
        let state = self.state;
        let {loginUser} = state;
        let app = window.ctx.ctxParam.app;
        let _menus = '';
        if(loginUser.role.body.indexOf('org_manager')>=0){
            _menus = APP_TOP_NAVS['org_manager'];
        }else{
            if(loginUser.role.body.indexOf('teacher')>=0){
                _menus = APP_TOP_NAVS['teacher'];
            }else{
                _menus = APP_TOP_NAVS['org_teacher'];
            }
        }
        return(
            <div className="module-app-org-nav-header layout-min-center">
                <div className="header-item-group start">
                    <div className="header-item"><img className="logo" src={logo} alt=""/>{app.logoText}</div>
                    <div className="header-item"></div>
                </div>
                <div className="header-item-group center">
                    <div className="site-menus  header-item">
                        <ul className={cx("menu-list")}>
                            {
                                _menus.map(function (menu) {
                                    return <AppPermissionValidate key={menu.name} permission={menu.permission}>
                                        <li key={menu.name} className={cx("menu-item",{selected:menu.name === state.moduleConfig.name })}><AppModuleLink className="menu-link" to={menu.name}>{menu.title}</AppModuleLink></li>
                                    </AppPermissionValidate>
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="header-item-group end">
                    <div className="header-item">
                        <AppAccountMenu parent={self} user={state.loginUser} schoolUser={state.schoolUser} school={state.school}/>
                    </div>

                    <div className="header-item">
                        {
                            state.org?<div className={'org-label-wrap'}><span className={'org-label'}>{state.org.name} [ {state.org.shortId} ]</span></div>:null
                        }
                        {
                            state.schoolUser && state.schoolUsers?
                                <AppHeadSchoolUserSelector
                                    selectedSchoolUser={state.schoolUser}
                                    schoolUsers={state.schoolUsers}
                                    onChange={self.handleSchoolUserChange.bind(self)}/>
                                :null
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default Module
