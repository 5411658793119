import Service from '../common/core/Service';

const displayName='OrgService';
class ModuleService extends Service{
    constructor(api) {
        super(api);
        let self = this;
        self.events = {};
        self.share= {
            list:(param,callback)=>{
                self.api.foundation.share.list(param,callback);
            }
        }
        self.voice= {
            analyse:{
                findByRecordIds: (param,callback)=>{
                    self.api.voice.analyse.findByRecordIds(param,callback);
                }
            }
        };
        self.asset= {
            getDeviceConfig:(param,callback)=>{
                self.api.foundation.conf(param,(res)=>{
                    if(res.conf && res.conf.length){
                        res.conf.forEach((c)=>{
                            if(c.body){
                                if('object' === typeof c.body.dtq && c.body.dtq.length === 0) {
                                    c.body.dtq = {}
                                }else if('string' === typeof c.body.dtq && c.body.dtq.length){
                                    c.body.dtq = JSON.parse(c.body.dtq);
                                }
                            }
                        })
                    }
                    callback && callback(res);
                })
            },
            saveDeviceConfig:(param,callback)=>{
                self.api.foundation.conf(param,(res)=>{
                    if(res.conf && res.conf.length){
                        res.conf.forEach((c)=>{
                            if(c.body){
                                if('object' === typeof c.body.dtq && c.body.dtq.length === 0) {
                                    c.body.dtq = {}
                                }else if('string' === typeof c.body.dtq && c.body.dtq.length){
                                    c.body.dtq = JSON.parse(c.body.dtq);
                                }
                            }
                        })
                    }
                    callback && callback(res);
                })
            },
            create:(param,callback)=>{
                self.api.castle.asset.create(param,callback);
            },
            update:(param,callback)=>{
                self.api.castle.asset.update(param,callback);
            },
            delete:(param,callback)=>{
                self.api.castle.asset.delete(param,callback);
            },
            detail:(param,callback)=>{
                self.api.castle.asset.detail(param,callback);
            },
            find:(param,callback)=>{
                self.api.castle.asset.find(param,callback);
            }
        };
        self.report={
            general:(param,callback)=>{
                self.api.student.report.general(param,callback);
            },
            moduleStatus:(param,callback)=>{
                self.api.student.report.moduleStatus(param,callback);
            },
            episode:(param,callback)=>{
                self.api.student.report.episode(param,callback);
            }

        };
        self.org={
            dashboard:(param,callback)=>{
                self.api.castle.org.dashboard(param,callback);
            },
            list:(param,callback)=>{
                self.api.castle.org.list(param,callback);
            },
            detail:(param,callback)=>{
                self.api.castle.org.detail(param,callback);
            },
            update:(param,callback)=>{
                self.api.castle.org.update(param,callback);
            },
            create:(param,callback)=>{
                self.api.castle.org.create(param,callback);
            },
            remove:(param,callback)=>{
                self.api.castle.org.remove(param,callback);
            },
            my:(param,callback)=>{
                self.api.castle.org.my(param,callback);
            },
            getByShorId:(param,callback)=>{
                self.api.castle.org.getByShorId(param,callback);
            },
            charge:{
                set:(param,callback)=>{
                    self.api.castle.org.charge.set(param,callback);
                },
                list:(param,callback)=>{
                    self.api.castle.org.charge.list(param,callback);
                }
            }
        };
        self.gcard= {
            list:(param,callback)=>{
                self.api.castle.org.gcard.list(param,callback);
            },
            categorys:(param,callback)=>{
                self.api.sale.admin.gcard.categorys(param,callback);
            },
            status:(param,callback)=>{
                self.api.castle.org.gcard.status(param,callback);
            },
            apply:(param,callback)=>{
                self.api.castle.org.gcard.apply(param,callback);
            },

        };
        self.man={
            list:(param,callback)=>{
                self.api.castle.org.man.list(param,callback);
            },
            join:(param,callback)=>{
                self.api.castle.org.man.join(param,callback);
            },
            update:(param,callback)=>{
                self.api.castle.org.man.update(param,callback);
            },
            info:(param,callback)=>{
                self.api.castle.org.man.info(param,callback);
            },
            remove:(param,callback)=>{
                self.api.castle.org.man.remove(param,callback);
            },
        };
        self.school={
            detail:(param,callback)=>{
                self.api.castle.school.detail(param, callback);
            },
            paging:(param,callback)=>{
                self.api.castle.school.paging(param, callback);
            },
            removeUser:(param,callback)=>{
                self.api.castle.school.user.delete(param, callback);
            },
            createUser:(param,callback)=> {
                self.api.castle.school.user.create(param,callback);
            }
        }
        self.grant={
            varrant:{
                paging:(param,callback)=>{
                    self.api.castle.varrant.paging(param, function (res) {
                        callback && callback(res);
                    })
                },
            },
        }
        self.userGrant={
            list:(param,callback)=>{
                self.api.castle.varrant.userGrant.list(param,callback);
            },
            add:(param,callback)=>{
                self.api.castle.varrant.userGrant.add(param,callback);
            },
            remove:(param,callback)=>{
                self.api.castle.varrant.userGrant.remove(param,callback);
            }
        };
        self.student={
            examnation:{
                reportInfo:function (param,callback) {
                    self.api.student.examnation.reportInfo(param,callback);
                },
                status:function (param,callback) {
                    self.api.student.examnation.status(param,callback);
                },
            },
            course:{
                userCourseList:(param,callback)=>{
                  self.api.student.grant.courseByUserIds(param, function (res) {
                      callback && callback(res);
                  })
                },
                studyInfo:(param,callback)=>{
                    self.api.castle.study.status(param, function (res) {
                        callback && callback(res);
                    })
                },
                orderCourse:(param,callback)=>{
                    self.api.student.grant.course(param,callback);
                },
                removeCourse:(param,callback)=>{
                    self.api.student.grant.remove(param,callback);
                }
            },
        };
        self.study={
            study:{
                courseStatus:(param,callback)=>{
                    self.api.castle.org.study.status(param, (res)=> {
                        callback && callback(res);
                    })
                },
                courseOrder:(param,callback) => {
                    self.api.castle.org.study.order(param, (res)=> {
                        callback && callback(res);
                    })
                },
            },
        };
        self.course={
            workbook:{
                findByIds:(param,callback)=>{
                    self.api.workbook.workbook.findByIds(param, function (res) {
                        callback && callback(res);
                    })
                },
                paging:(param,callback)=>{
                    self.api.workbook.workbook.paging(param, function (res) {
                        callback && callback(res);
                    })
                }
            },
            gallery:{
                findByIds:(param,callback)=>{
                    self.api.workbook.gallery.findByIds(param, function (res) {
                        callback && callback(res);
                    })
                },
                getListByWorkbookId:(param,callback)=>{
                    self.api.workbook.gallery.getList(param, function (res) {
                        callback && callback(res);
                    })
                },
            },
            examnation:{
                findByIds:(param,callback)=>{
                    self.api.examnation.exam.findByIds(param, function (res) {
                        callback && callback(res);
                    })
                },
                paging:(param,callback)=>{
                    self.api.examnation.exam.paging(param, function (res) {
                        callback && callback(res);
                    })
                },
            },
            course:{
              findByIds:(param,callback)=>{
                  self.api.course.course.findByIds(param, function (res) {
                      callback && callback(res);
                  })
              }
            },
            lesson:{
                getLessonListByCourseId:(param,callback)=>{
                    self.api.course.lesson.getList(param, function (res) {
                        callback && callback(res);
                    })
                }
            }
        };
        self.user={
            paging: (param, callback) => {
                self.api.auth.paging(param, callback);
            },
            registPassport : (param, callback) => {
                self.api.auth.regist(param, callback);
            },
            update:(param, callback)=>{
                self.api.auth.update(param, callback);
            }
        };
    }
}
ModuleService.displayName=displayName;
export default ModuleService