let modules=[
    {
        title:'首页',
        moduleName:'home',
        menu:'home',
        router:['/'],
        component:(cb)=>{
            import('../dealer_module/home/index').then(({default: component}) => {
                cb(component)
            })
        }
    },
];
export default modules;