import React, {Component} from 'react';
import cx from 'classnames';

import audioEmpty from '@media/empty.mp3';
class Module extends Component {
    constructor(props, context) {
        super(props, context);

        this.initFunctions(this);
        this.state = this.getInitState(props, context);
    }

    getInitState(props, context) {
        let state={
            src:'',
            events:{}
        };
        return state;
    }

    //componentWillReceiveProps(props) {}

    componentDidMount() {
        setTimeout(this.init,300)
    }

    //componentWillUnmount() {}
    initFunctions(self) {
        self.handleEvent=(name,e)=>{
            let {events}=self.state;
            if(events && typeof events[name] === 'function'){
                events[name](e,self);
            }
        };
        self.isPaused=()=>{
            return self.audio.paused;
        };
        self.reset=(callback)=>{
            if(self.audio){
                self.setState({src:'',events:{}}, ()=>{
                    self.audio.pause();
                    self.audio.src='';
                    callback && callback();
                })
            }
        };
        self.setUrl=(src,events,callback)=>{
            self.reset(()=>{
                if(self.audio){
                    self.setState({src,events}, ()=>{
                        self.audio.src=src;
                        // self.load();
                        callback && callback();
                    })
                }
            })
        };
        self.load=()=>{
            if(self.state.src){
                self.audio.load();
            }
        };
        self.play=()=>{
            self.audio && self.audio.play();
        };
        self.pause=()=>{
            self.audio && self.audio.pause();
        };
        self.prePlay=()=>{
            if(!self.state.src){
                self.setUrl(audioEmpty,{},()=>{
                    self.play();
                });
            }
            if(self.audio){
                window.document.removeEventListener('touchstart', self.prePlay);
            }
        };
        self.init=()=>{
            self.audio && ['error','pause','play','playing','timeupdate','ended','emptied','canplay'].forEach((name)=>{
                self.audio.addEventListener(name,(e)=>{
                    self.handleEvent(name,e);
                })
            })
            window.document.addEventListener('touchstart', self.prePlay);

        }
    }

    render() {
        let self = this;
        //let state=self.state;
        // let {match,location}=self.props
        return (
            <audio ref={(el)=>{self.audio=el;}} />
        );
    }
}

export default Module
