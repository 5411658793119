import React, {Component} from 'react';
import cx from 'classnames';
class Module extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return(
            <a className={cx("app-module-link",this.props.className)} href={`#/${this.props.to}`}>
                {this.props.children}
            </a>
        )
    }
}
export default Module
