import React, {Component} from 'react';
import cx from 'classnames';
import {Icon} from 'antd';
import './index.css';

class Module extends Component {
    constructor(props, context) {
        super(props, context);

        this.initFunctions(this);
        this.state = this.getInitState(props, context);
    }

    getInitState(props, context) {
        let state = {
            // school:props.school,
            text:props.text,
            move:'slideInRight'
        };

        return state;
    }

    //componentWillReceiveProps(props) {}

    componentDidMount() {
        this.timer = setTimeout(this.close,5000);
    }

    //componentWillUnmount() {}
    initFunctions(self) {
        self.close=(e)=>{
            e && e.preventDefault();
            e && e.stopPropagation();
            if(self.timer){
                clearTimeout(self.timer);
            }
            self.timer=undefined;
            self.setState({
                move:'slideOutRight'
            },()=>{
                setTimeout(()=>{
                    self.props.onClose && self.props.onClose()
                },1000)
            })

        }
    }

    render() {
        let self = this;
        let state = self.state;
        let {move,text} = state;
        return (
            <div className={cx("message-tip",'animated','quick',move, this.props.className)}>
                <div className={'info-wrap'}>
                    <div className={'col c1'}><Icon className={'icon'} type="bulb" /></div>
                    <div className={'col c2'}>
                        <h6>温馨提示</h6>
                        <p>{text}</p>
                    </div>
                    <a className={'btn-close'} onClick={self.close}>×</a>
                </div>
            </div>
        )

    }
}

export default Module
