import logoYidong from '@img/cmhe-gzh_100.png';
export default {
    "tutor":{
        "cmcc":{
            logoImg: logoYidong,
        },
        "cmhe-gzh":{
            disableCastleEdit:true,
            disableCastleMemberEdit:true
        },
    }
}