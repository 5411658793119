import './css/Root.css';
import '@css/ui.css';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ConfigProvider  } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale-provider/zh_CN';
// import * as registerServiceWorker from './registerServiceWorker';
moment.locale('zh-cn');
window.PointerEvent = void 0;
ReactDOM.render(<ConfigProvider  locale={zhCN}>
    <App />
</ConfigProvider>, document.getElementById('root'));
// registerServiceWorker.unregister();
